import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,

} from '@chakra-ui/react'
import { PasswordField } from './PasswordField'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthCreator } from '../../../Redux/Auth/actionCreators'
import { Link } from 'react-router-dom'
import Toast from '../../../Components/Toast'

const Login = () => {
  const [userData,setUserData]=useState({email:"",password:""});
  const dispatch=useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  function handleSign(){
    if(userData.email=="" && userData.password==""){
      Toast("Please fill in the information");
    }
    else if(userData.email=="" && !userData.password==""){
      Toast("Email is required")
    }
    else if(userData.email!=="" && userData.password==""){
      Toast("Password is required")
    }
    else
    dispatch(setAuthCreator(userData));
  }
  return <Container
    maxW="lg"
    py={{
      base: '12',
      md: '24',
    }}
    px={{
      base: '0',
      sm: '8',
    }}
  >
    <Stack spacing="8">
      <Stack spacing="6">

        <Stack
          spacing={{
            base: '2',
            md: '3',
          }}
          textAlign="center"
        >
          <Heading
            size={{
              base: 'xs',
              md: 'sm',
            }}
          >
            Log in to your account
          </Heading>
        </Stack>
      </Stack>
      <Box
        py={{
          base: '0',
          sm: '8',
        }}
        px={{
          base: '4',
          sm: '10',
        }}
        bg={{
          base: 'transparent',
          sm: 'bg-surface',
        }}
        boxShadow={{
          base: 'none',
          sm: 'md',
        }}
        borderRadius={{
          base: 'none',
          sm: 'xl',
        }}
      >
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" type="email" onChange={(e)=>setUserData({...userData,email:e.target.value})} />
            </FormControl>
            <PasswordField onChange={(e)=>setUserData({...userData,password:e.target.value})}  />
          </Stack>
          <HStack justify="space-between">
            <Checkbox defaultChecked>Remember me</Checkbox>
            <Link to='/sendotp' variant="link" colorScheme="blue" size="sm">
              Forgot password?
            </Link>
          </HStack>
          <Stack spacing="6">
            <Button isLoading={isLoading} onClick={handleSign} variant="primary" colorScheme={'red'} bg={'pink.400'} color={"white"}>Sign in</Button>

          </Stack>
        </Stack>
      </Box>
    </Stack>
  </Container>
}


export default Login;