import { QUESTION_ADDED_SUCCESSFUL } from "./actionType"





export const getQuizData = () => async (dispatch) => {
    try {
        
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/quiz/getquiz`)

        const jsonData = await data.json()

        dispatch({ type: QUESTION_ADDED_SUCCESSFUL, payload: jsonData })
    } catch (err) {
        console.log(err)
    }



}


