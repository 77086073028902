


const initialState = {
    questionBank : [],
    TotalPage:0
}



export  const questionReducer = (state=initialState,action) => {
    const {type,payload} = action;

    switch(type){
        case "QUESTION_ADDED_SUCCESSFUL" : return {...state, questionBank : payload.data,TotalPage:payload.total }

       default :   return state
    }

}