import React from "react";
import "./HealthBar.css";

const HealthBar = (props) => {
  return (
    <div className="health-bar">
      <div
        className="health-bar-inner"
        style={{ width: `${props.width}%`,backgroundColor:`${props.width>60?"#33cc33":props.width>30?"#f39f03":"#cc3333"}`}}>
        </div>
    </div>
  );
};

export default HealthBar;
