import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import axios from "axios";
import Toast from "noori-toast";
import React, { useState } from "react"

export function InitialFocus({userData}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading,setLoading]=useState(false);
    const [verify,setVerify]=useState(true)
    const initialRef = React.useRef(null);
    const [otp,setOtp]=useState(0)
    const [password,setpassword]=useState("")
    function handleSign(){

        if(userData.email!==""){
        setLoading(true)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`,userData)
        .then((e)=>{
          if(e?.status==200){
          setLoading(false)
          Toast(`${e?.data?.msg}`,"success","top-center")
          onOpen();
          }
          else{
          setLoading(false)
          Toast(`${e?.response?.data?.msg}`,"error","top-center")
          }
        })
        .catch(response=>{
          setLoading(false)
          Toast(`${response?.response?.data?.msg}`,"error","top-center");
        })}
        else{
          setLoading(false)
          return
        }
      }
      function handleverify(){
         if(otp<99999){
            Toast('OTP Should be 6 digit','warning',"top-center")
         }
         else{
            setLoading(true)
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/verifyotp`,{otp:Number(otp),email:userData?.email})
            .then((e)=>{
                if(e.status==200){
                    Toast(`${e?.data?.msg}`,"success","top-center");
                    setVerify(false);
                    setLoading(false)
                }
                else{
                    setLoading(false)
                    Toast(`${e?.response?.data?.msg}`,"error","top-center")
                }
            }).catch(response=>{
                setLoading(false)
                Toast(`${response?.response?.data?.msg}`,"error","top-center");
              })
         }
      }
      function handleChange(){

        if(password.length<5){
            Toast("Enter password greator than 5 character",'warning','top-center');
            return
        }
        setLoading(true)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/changepassword`,{newPassword:password,email:userData?.email})
            .then((e)=>{
                console.log(e,"then")
                if(e.status==200){
                    Toast(`${e?.data?.msg}`,"success","top-center");
                    setLoading(false)
                    setVerify(false);
                    onClose();
                }
                else{ 
                    setLoading(false) 
                    Toast(`${e?.response?.data?.msg}`,"error","top-center")
                }
            }).catch(response=>{
                setLoading(false)
                Toast(`${response?.response?.data?.msg}`,"error","top-center");
              })
      }
    
    return (
      <>
        <Button variant="primary" colorScheme={'red'} 
        bg={'pink.400'} color={"white"}
         isLoading={loading} onClick={handleSign}>Send Otp</Button>
  
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Verify Otp</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
             {verify && <FormControl>
                <FormLabel>Enter Otp</FormLabel>
                <Input type="number" onChange={(e)=>setOtp(e.target.value)} placeholder='Enter 6 digit Otp' />
              </FormControl>}
  
           {!verify &&  <FormControl mt={4}>
                <FormLabel>Enter New Password</FormLabel>
                <Input onChange={(e)=>setpassword(e.target.value)} placeholder='Enter New Password' />
              </FormControl>}
            </ModalBody>
  
            <ModalFooter>
              {verify ? <Button isLoading={loading} colorScheme='blue' mr={3} onClick={handleverify}>
                Verify
              </Button>:<Button isLoading={loading} colorScheme='blue' mr={3} onClick={handleChange}>
                Change Password
              </Button>}
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }