import { Box , Button, Flex,Heading, Radio, RadioGroup,VStack} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getLocalStorageData, saveToLocalStorage } from '../../Utils/localStorage';
import Pagination from './Pagination';
import {useSearchParams} from 'react-router-dom';

const SingleQuestion = ({data,filterObject,time,second}) => {
  const [searchParams] = useSearchParams();

  const questionNo = searchParams.get("questionNo");
    const [check,setcheck]=useState(""); // only using for re-rendering.
    const handleChange=(e)=>{
      let marks=0
      if(data?.correct==e){
        marks=data?.marks
      };
      let temp=getLocalStorageData(filterObject._id);
      saveToLocalStorage(filterObject._id,{...temp,status:filterObject.status,attempted:{...temp.attempted,[data?._id]:[marks,e]}});
      setcheck(e);
      return
    } 
    return (
           <Flex   
           direction={{base:"column",lg:'row'}} padding={5}
           justifyContent={'center'}
           bgGradient={[
            'linear(to-tr, teal.300, yellow.400)',
            'linear(to-t, blue.200, teal.500)',
            'linear(to-b, orange.100, purple.300)',
          ]}
           gap={5}
           > <VStack>   
            <Flex backgroundColor={'white'} alignSelf={'flex-start'} width={'300px'} direction={"column"} padding="10px">
                <Flex><Box width={"150px"}><b>Candidate Name</b> : </Box> <Box width={"150px"}> {getLocalStorageData(filterObject?._id)?.name||""} </Box> </Flex>
                <Flex><Box width={"150px"}><b>Topic</b> :  </Box> <Box width={"150px"}>{filterObject?.title}</Box> </Flex>
                <Flex><Box width={"150px"}><b>Total Marks</b> : </Box> <Box width={"150px"}> {filterObject?.totalMarks} </Box> </Flex>
                <Flex><Box width={"150px"}><b>Time Remaining</b> :  </Box> <Box width={"150px"}>{time}:{second<10?0:""}{second} Minutes</Box> </Flex>
           </Flex> 
           </VStack>
                {data &&  <Flex
                      borderRadius={10}
                      fontSize={{base:"14px",lg:"16px"}}
                      width={{lg:'400px',base:"300px"}}
                     /*  width={{lg:"70%",sm:"100%",md:"100%"}} */ 
                      boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
                      backgroundColor={'white'}
                      >
                                            
                     <Flex direction='column' padding={5} gap={5} >
                       
                        <Heading fontSize={{base:"16px",lg:"18px"}} size='md'>Q{questionNo}. {data?.question}</Heading>                        
                          <RadioGroup onChange={(e)=>handleChange(e)}   value={getLocalStorageData(filterObject._id)?.attempted?.[data?._id]?.[1]||''} >
                            <VStack direction='row' alignItems={'start'}  >
                              <Box>A. {data?.option1}</Box>
                              <Box>B. {data?.option2}</Box>
                              <Box>C. {data?.option3}</Box>
                              <Box>D. {data?.option4}</Box>
                              <br/>
                              <Flex fontSize={{base:"14px",lg:"16px"}}>
                                    <Radio value='option1'  backgroundColor={'white'}></Radio>
                                    <Button onClick={()=>handleChange('option1')} margin={2}
                                     color='white'
                                     fontWeight='bold'
                                    _hover={{
                                      bgGradient: 'linear(to-r, teal.500, green.500)',
                                    }}
                                    bgGradient={getLocalStorageData(filterObject._id)?.attempted?.[data?._id]?.[1]=="option1"?
                                    'linear(to-r, teal.500, green.500)':'linear(to-r, red.500, yellow.500)'}
                                     fontSize={{base:"12px",lg:"16px"}}
                                     >Option A</Button>
                                    <Radio value='option2'  backgroundColor={'white'}></Radio>
                                    <Button onClick={()=>handleChange('option2')} margin={2}
                                    color='white'
                                    fontWeight='bold'
                                    _hover={{
                                      bgGradient: 'linear(to-r, teal.500, green.500)',
                                    }}
                                    bgGradient={getLocalStorageData(filterObject._id)?.attempted?.[data?._id]?.[1]=="option2"?
                                    'linear(to-r, teal.500, green.500)':'linear(to-r, red.500, yellow.500)'}
                                    fontSize={{base:"12px",lg:"16px"}}
                                    >Option B</Button>
                              </Flex>
                              <Flex>
                                <Radio value='option3'  backgroundColor={'white'}></Radio>
                                <Button onClick={()=>handleChange('option3')} margin={2}
                                 color='white'
                                 fontWeight='bold'
                                    _hover={{
                                      bgGradient: 'linear(to-r, teal.500, green.500)',
                                    }}
                                    bgGradient={getLocalStorageData(filterObject._id)?.attempted?.[data?._id]?.[1]=="option3"?
                                    'linear(to-r, teal.500, green.500)':'linear(to-r, red.500, yellow.500)'}
                                fontSize={{base:"12px",lg:"16px"}}
                                >Option C</Button>
                                <Radio value='option4' backgroundColor={'white'}></Radio>
                                <Button onClick={()=>handleChange('option4')} margin={2}
                                 color='white'
                                 fontWeight='bold'
                                    _hover={{
                                      bgGradient: 'linear(to-r, teal.500, green.500)',
                                    }}
                                    bgGradient={getLocalStorageData(filterObject._id)?.attempted?.[data?._id]?.[1]=="option4"?
                                    'linear(to-r, teal.500, green.500)':'linear(to-r, red.500, yellow.500)'}
                                fontSize={{base:"12px",lg:"16px"}}
                                >Option D</Button></Flex>
                              <Flex></Flex>
                              
                              
                              
                              
                            </VStack>
                          </RadioGroup>
                      </Flex>
                  </Flex>
                  }
                  <Box p={5}
                    
                  borderRadius={10}
                  backgroundColor={'white'}
                  bg='white.300' 
                  width={{lg:"300px",base:'300px'}} 
                  maxH={400} 
                  overflowY='scroll' shadow="2xl" >
                    {
                       <Pagination />
                    }
                     </Box>

                </Flex>)
}

export default SingleQuestion
