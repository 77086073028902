import { Box, Tab, TabList, TabPanel, TabPanels,Tabs } from '@chakra-ui/react'
import React from 'react'
import UserCreate from './UserCreate'
import ListUser from './ListUser'
import UserUpdate from './UserUpdate'

const UserTabs = () => {
  return (
    <Box backgroundColor={'white'}>
        <Tabs isFitted variant='enclosed'>
        <TabList>
            <Tab>Users List</Tab>
            <Tab>Create User</Tab>
            <Tab>Update User</Tab>
        </TabList>
        <TabPanels>
            <TabPanel><ListUser/></TabPanel>
            <TabPanel><UserCreate/></TabPanel>
            <TabPanel><UserUpdate/></TabPanel>
        </TabPanels>
        </Tabs>
    </Box>
  )
}

export default UserTabs