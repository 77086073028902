import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { deleteFromLocalStorage, getLocalStorageData } from '../../../Utils/localStorage';
import ReactTypingSpeedTest from '@skpts/react-typing-speed-test';
import Toast from '../../../Components/Toast';

const TypingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clearing data if component refreshes or unmounts
    return () => deleteFromLocalStorage("typingData");
  }, []);

  const data = getLocalStorageData("typingData") || null;
  if (!data) {
    return <Navigate to='/notfound' />;
  }

  const handleFinish = (e) => {
    Toast("You will be redirected to Main page in 10 sec","information","top-center")
    setTimeout(()=>{
        navigate('/typingTest');
    },10000)

  };

  return (
    <div style={{maxHeight:"100vh",overflowY:"hidden"}} >       
          <ReactTypingSpeedTest
            color="light"
            testContent={data?.data}
            onFinish={handleFinish}
            duration={data?.time * 60}
            showResult={true}
            startTest={true}
          />
    </div>
  );
};

export default TypingPage;
