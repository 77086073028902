import React from 'react'
import style from './LectureCard.module.css';
import { Badge } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
const LectureCard = () => {
  return (
    <div className={style.container}>
        <div className={style.leftContainer}>
                <h2> Batch Time </h2>
                <h2> Live Session</h2>
        </div>
        <div className={style.rightContainer}>
            <div className={style.topRcontainer}><h2>ADCA</h2></div>
            <div className={style.middleRcontainer}>
                <div>
                    <p>Mohammad Ayaz Noori </p>
                </div>
                <Link href={'https://us06web.zoom.us/j/8740171403?pwd=dDNwVmtOdGphSkxYQVBxVWwyQTR1dz09'} color={'white'} className={style.joinButton}  >Join Now</Link></div>
            <div className={style.bottomRcontainer}><Badge colorScheme='green'>ADCA</Badge></div>
        </div>
    </div>
  )
}

export default LectureCard