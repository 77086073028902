import { useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
export const PublicRoute = ({ children }) => {
    const { isAuth,role } = useSelector((state) => state.auth);
  
  if (isAuth) {
      return <Navigate to={`/${role}/dashboard`} />;
   }
  
   return children;
};
