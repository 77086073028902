import { SET_AUTH, LOGOUT_USER, ADD_USER, LOGIN_REQUEST, LOGIN_ERROR } from "./actionTypes";
import { getLocalStorageData } from "../../Utils/localStorage";

//change here auth
const initState = {
   isAuth:
      getLocalStorageData(`araoalae`) &&
      getLocalStorageData(`taoakaean`)
         ? true
         : false,
   role: getLocalStorageData(`araoalae`) || null,
   token: getLocalStorageData(`taoakaean`) || null,
   isLoading:false,
   isError:false
};

export const authReducer = (oldState = initState, action) => {
   switch (action.type) {
      case LOGIN_REQUEST:
          return {
         ...oldState,
         isLoading:true,
         isError:false
      }
      case SET_AUTH:
         return {
            ...oldState,
            isAuth: true,
            role: action.payload.role,
            token: action.payload.token,
            isLoading:false,
            isError:false
         };
      case LOGIN_ERROR:
         return {
            ...oldState,
            isLoading:false,
            isError:true
         };

      case LOGOUT_USER:
         return {
            ...oldState,
            isAuth: false,
            role: null,
            token: null,
            isLoading:false,
            isError:false
         };
      
      case ADD_USER:return {...oldState,user:action.payload,isLoading:false}

      default: 
         return oldState;
   }
};
