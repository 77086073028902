import React from 'react'
import style from './LectureCard.module.css';
import { Badge, Link } from '@chakra-ui/react';
const PLecture = () => {
  return (
    <div className={style.container}>
        <div className={style.leftContainer}>
                <h2> Link </h2>
                <h2> Video Session</h2>
        </div>
        <div className={style.rightContainer}>
            <div className={style.topRcontainer}><h2>Topic - ADCA</h2></div>
            <div className={style.middleRcontainer}>
                <div>
                    <p>Mohammad Ayaz Noori </p>
                </div>
                <Link href={'https://us06web.zoom.us/j/8740171403?pwd=dDNwVmtOdGphSkxYQVBxVWwyQTR1dz09'} color={'white'} className={style.joinButton}  >Watch Now</Link></div>
            <div className={style.bottomRcontainer}><Badge colorScheme='green'>ADCA</Badge></div>
        </div>
    </div>
  )
}

export default PLecture