import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,

  Icon,
  IconButton,
  createIcon,
  List,
  ListItem,
  ListIcon,
  Link,
  VStack,
  color,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdCheckCircle, MdSettings } from 'react-icons/md'
import {  useNavigate } from 'react-router-dom';
export default function Banner() {
  const [notice,setNotice]=useState([]);
  const navigate = useNavigate();
  const [loading,setLoading]=useState(true)

  const fetchNotices = async () => {
    
    try {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/notice/get`);
        const data = await response.json();
        setNotice(data);
        setLoading(false)
    } catch (error) {
        setLoading(false)
        console.error('Error fetching notices:', error);
    }
};
useEffect(()=>{
   fetchNotices();
},[])
  return (
    <Container maxW={'7xl'} >
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'red.400',
                zIndex: -1,
              }}>
              Welcome,
            </Text>
            <br />
            <Text as={'span'} color={'red.400'}>
              Good to see you {':)'}
            </Text>
          </Heading>
          <Text color={'grey.500'} as={'i'}>
            <b>Noori computer classes</b> offer a range of courses to help individuals gain knowledge
            and skills in various computer-related fields. The courses offered, such as ADCA
            (Advance Diploma in Computer Applications), DCA (Diploma in Computer Applications),
            Tally, and web design (both basic and advanced), are designed to cater to the needs
            of students with varying levels of experience and expertise. Our Institute Run by Al
            Kabir Educational foundation (<b>Regd. Under Society Registration Act 21, 1860 Govt. of Bihar)
              .</b> Whether you are a beginner or looking to expand your skillset, Noori
            computer classes are a great way to enhance your knowledge and advance your
            career in the world of technology.
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}>
            <Button
              onClick={() => navigate('/contact')}
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              colorScheme={'red'}
              bg={'red.400'}
              _hover={{ bg: 'red.500' }}>
              Get started
            </Button>

          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Box
            position={'relative'}
            height={'300px'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={'full'}
            overflow={'hidden'}
            
          >
            <Heading
              bgColor={'red.400'}
              color={'whitesmoke'}
              align={'center'}
              position="relative"
              zIndex={1}
            >
              Important Updates
            </Heading>
            <VStack
              align={'left'}
              padding={'5'}
              position="relative"
              zIndex={0}
            >
              <List spacing={3}>
                {loading && <><Spinner/> <Text  >Loading...</Text></> }
                {!loading && notice.length==0 && <Text  fontWeight={'bold'} fontSize={'medium'}>
                              No New Notification</Text>}
                {!loading && notice?.map(ele => <ListItem key={ele._id}>
                  <Flex justifyContent={'space-between'} >
                    <Flex alignItems={'center'}>
                           <ListIcon  as={MdCheckCircle} />
                           <Text fontWeight={'bold'} fontSize={'medium'}>{ele?.date} {" - "}
                             {ele.message}</Text>
                    </Flex>
                  {!ele?.link =="" && <Link href={ele?.link} target='_blank' color='blue'  style={{color:"blue"}}>Link</Link>
                  }</Flex>
                </ListItem>)}
              </List>
            </VStack>
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}

export const Blob = (props) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};