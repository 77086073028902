import { Tabs, TabList, TabPanels, Tab, TabPanel, Box} from '@chakra-ui/react'
import React from 'react'
import LectureCard from './component.jsx/LectureCard'
import PLecture from './component.jsx/PLecture'

const Lecture = () => {
  return (
    <Box bgColor={'white'} height={"100vh"}>
    <Tabs isFitted variant='enclosed' colorScheme={'blue'}>
    <TabList mb='1em' >
      <Tab>Live Lecture</Tab>
      <Tab>Previous Lecture</Tab>
    </TabList>
    <TabPanels>
      <TabPanel>
       <LectureCard/>
      </TabPanel>
      <TabPanel>
        <PLecture/>
      </TabPanel>
    </TabPanels>
  </Tabs>
  </Box>
  )
}

export default Lecture
