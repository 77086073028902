import React, { useEffect, useState, } from 'react';
import { Box, Flex, Heading, Radio, RadioGroup, VStack, Text, Center } from '@chakra-ui/react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { deleteFromLocalStorage, getLocalStorageData } from '../../Utils/localStorage';
import { useSelector } from 'react-redux';
import { useTitle } from '../../Utils/useTitle';
import axios from 'axios';
import Toast from 'noori-toast/dist';

const QuizResult = () => {
  useTitle("Noori Computer Classes || Quiz Result")
  const { id } = useParams();
  const [data, setData] = useState({});
  const [questions, setquestions] = useState([])
  const { questionBank } = useSelector(store => store.question);
  const navigate = useNavigate()
  const [tm, settm] = useState(0)
  useEffect(() => {
    if (!getLocalStorageData(id)) {
      return navigate('/quiz')
    }
    const currentTime = new Date(); // Get current time
    const formattedTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    let temp = getLocalStorageData(id);
    setData({ ...temp, quizId: id })
    !temp.status && axios.post(`${process.env.REACT_APP_BACKEND_URL || "https://tame-tan-clam-veil.cyclic.app"}/quiz/postresponse`, {
      ...temp,
      quizId: id,
      submittedTime: formattedTime // Include current time in the data
    })
      .then((e) => Toast("Response Submitted Successfully!", "success", "top-center"))
      .catch((e) => { Toast("Some Error! Contact Admin dont refresh"); console.log(e) })
    const filterData = questionBank?.find((el) => el._id == id)
    setquestions(filterData);
    deleteFromLocalStorage(id)
  }, [])
  useEffect(() => {
    let m = 0;
    questions && questions?.questions?.map((ele) => {
      if (ele.correct == data?.attempted?.[ele?._id]?.[1]) {
        m = m + Number(data?.attempted?.[ele?._id][0]);
      }
    });
    settm(m)
  }, [questions])
  return (
    <Flex
      backgroundColor={'#F0FFF4'}  // Light green background
      padding={10}
      paddingTop={8}  // Reduce top padding for a sleeker look
      direction={'column'}
      alignItems={'center'}
      borderRadius={10}
      boxShadow='0 4px 6px rgba(0, 0, 0, 0.1)'
      width={{ lg: '70%', md: '80%', sm: '90%' }}  // Adjust width for responsiveness
      margin="auto"  // Center the card horizontally
    >
      <Heading color={'#1A365D'} fontFamily={'cursive'} fontSize="2xl" mb={4}>
        Your Responses
      </Heading>
      <Box fontSize="lg" fontWeight="bold" mb={2}>
        Name: {data.name}
      </Box>
      <Box fontSize="lg" mb={2}>
        Email: {data.email}
      </Box>
      {data.status && (
        <Box fontSize="lg" fontWeight="bold" mb={4} color="#2E8B57">  {/* Dark green for Marks */}
          Marks: {tm} / {questions?.totalMarks}
        </Box>
      )}  {questions && questions?.questions?.map((ele, i) => <Flex key={i + ele?._id} backgroundColor={'white'} marginTop={10} width={{ lg: "70%", sm: "100%" }}
        boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px'
        marginRight={5}
        marginLeft={5}
        borderRadius={10}
      >

        <Flex direction='column' padding={10} gap={5}>
          <Heading size='md'> {ele?.question}</Heading>
          <RadioGroup _disabled={true} defaultValue={data?.attempted?.[ele?._id]?.[1]} >
            <VStack direction='row' alignItems={'start'} >
              <Flex><Radio isDisabled value='option1' ></Radio><Box marginLeft={5}> {ele?.option1}</Box></Flex>
              <Flex><Radio isDisabled value='option2'></Radio><Box marginLeft={5}> {ele?.option2}</Box></Flex>
              <Flex><Radio isDisabled value='option3'></Radio><Box marginLeft={5}> {ele?.option3}</Box></Flex>
              <Flex><Radio isDisabled value='option4'></Radio><Box marginLeft={5}> {ele?.option4}</Box></Flex>
            </VStack>
          </RadioGroup>
          <Text color='green'>Correct : {ele[ele.correct]} </Text>
          <Text color={ele[ele.correct] == ele?.[data?.attempted?.[ele?._id]?.[1]] ? "green" : "red"}>
            Your Response : {ele?.[data?.attempted?.[ele?._id]?.[1]]}  </Text>
        </Flex>
      </Flex>)
      }

    </Flex>
  )
}

export default QuizResult
