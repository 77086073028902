import React, { useState, useEffect, useRef } from "react";
import HealthBar from "./HealthBar";
import "./TypingGame.css";

const TypingGame = (props) => {
  let interval=useRef();
  let gameLoop=useRef();
  const [characters, setCharacters] = useState([]);
  const [score, setScore] = useState(0);
  const [health, setHealth] = useState(100);
  const [gameOver, setGameOver] = useState(false);
  const [gameRunning, setGameRunning] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameSpeed, setGameSpeed] = useState(1); // 1: Low, 2: Medium, 3: High
  const [characterTypes, setCharacterTypes] = useState("all"); // "all", "capital", "small", "number", "symbol"

  const charSets = {
    all: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()",
    capital: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    small: "abcdefghijklmnopqrstuvwxyz",
    number: "0123456789",
    symbol: "!@#$%^&*()",
  };

  const randomChar = () => {
    const charSet = charSets[characterTypes];
    return charSet.charAt(Math.floor(Math.random() * charSet.length));
  };

  const addNewCharacter = () => {
    if (gameRunning && !gameOver) {
      const character = randomChar();
      const newCharacter = {
        character,
        xPosition: Math.random() * 90,
        yPosition: 0,
        active: true,
      };
      setCharacters((prevCharacters) => [...prevCharacters, newCharacter]);
    }
  };

  const updateCharacterPositions = () => {
    if (gameRunning && !gameOver) {
      setCharacters((prevCharacters) =>
        prevCharacters.map((character) => {
          if (character.active) {
            character.yPosition += gameSpeed; // Adjust falling speed here based on gameSpeed
            if (character.yPosition >= 95) {
              character.active = false;
              setHealth((prevHealth) => prevHealth - 10);
            }
          }
          return character;
        })
      );
    }
  };

  const handleKeyPress = (event) => {
    if (gameRunning && !gameOver) {
      const typedChar = event.key;
      setCharacters((prevCharacters) =>
        prevCharacters.map((character) => {
          if (character.active && character.character === typedChar) {
            character.active = false;
            setScore((prevScore) => prevScore + 1);
          }
          return character;
        })
      );
    }
  };

  const handleStartGame = () => {
    setGameStarted(true);
    setGameRunning(true);
    setGameOver(false);
    setCharacters([]);
    setScore(0);
    setHealth(100);
    addNewCharacter();
  };

  useEffect(() => {
    if (gameStarted ) {
       interval.current = setInterval(addNewCharacter, 1000 / gameSpeed); // Adjust character spawn rate based on gameSpeed
       gameLoop.current = setInterval(updateCharacterPositions, 50);
      return () => {
        clearInterval(interval);
        clearInterval(gameLoop);
      };
    }
  }, [gameStarted, gameSpeed]);

  useEffect(() => {
    if (health === 0) {
      setGameOver(true);
      setGameRunning(false);
      clearInterval(gameLoop.current);
      clearInterval(interval.current)
    }
 
  }, [health, gameRunning]);

  useEffect(() => {
    if (gameStarted && health!==0) {
      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
    else{
      window.removeEventListener("keydown", handleKeyPress);
    }
  }, [gameStarted,health]);

  return (
    <div className="typing-game-container">
      {!gameStarted ? (
        <div className="game-settings-card">
          <h1 style={{fontSize:"25px",fontWeight:"bold"}}>Typing Game</h1><br/>
          <div>
          <button onClick={handleStartGame}>Start Game</button>
          </div>
          <div className="container">
            <label><b>Speed: </b>&nbsp;</label>
            <input
              type="radio"
              id="lowSpeed"
              name="speed"
              value="1"
              checked={gameSpeed === 1}
              onChange={() => setGameSpeed(1)}
            />&nbsp;
            <label htmlFor="lowSpeed">Low &nbsp;</label>
            <input
              type="radio"
              id="mediumSpeed"
              name="speed"
              value="2"
              checked={gameSpeed === 2}
              onChange={() => setGameSpeed(2)}
            />&nbsp;
            <label htmlFor="mediumSpeed">Medium &nbsp; </label>
            <input
              type="radio"
              id="highSpeed"
              name="speed"
              value="3"
              checked={gameSpeed === 3}
              onChange={() => setGameSpeed(3)}
            />
            <label htmlFor="highSpeed">High &nbsp;</label>
          </div>
          <br/>
          <div className="container">
            <label><b>Character Type:</b>&nbsp;</label>
            <input
              type="radio"
              id="allChars"
              name="charType"
              value="all"
              checked={characterTypes === "all"}
              onChange={() => setCharacterTypes("all")}
            />
            <label htmlFor="allChars">All &nbsp;</label>
            <input
              type="radio"
              id="capitalChars"
              name="charType"
              value="capital"
              checked={characterTypes === "capital"}
              onChange={() => setCharacterTypes("capital")}
            />
            <label htmlFor="capitalChars">Capital &nbsp;</label>
            <input
              type="radio"
              id="smallChars"
              name="charType"
              value="small"
              checked={characterTypes === "small"}
              onChange={() => setCharacterTypes("small")}
            />
            <label htmlFor="smallChars">Small &nbsp;</label>
            <input
              type="radio"
              id="numberChars"
              name="charType"
              value="number"
              checked={characterTypes === "number"}
              onChange={() => setCharacterTypes("number")}
            />
            <label htmlFor="numberChars">Number &nbsp;</label>
            <div style={{textAlign:"right"}}>
            <input
              type="radio"
              id="symbolChars"
              name="charType"
              value="symbol"
              checked={characterTypes === "symbol"}
              onChange={() => setCharacterTypes("symbol")}
            />
            <label htmlFor="symbolChars">Symbol &nbsp;</label>
            </div>
          </div>
        </div>
      ) : (
        <>
          {gameOver ? (
          <div className="game-over-card">
          <h2>Game Over</h2>
          <p>Your Score: {score}</p>
          <button onClick={() => setGameStarted(!gameStarted)}>Restart</button>
          {/* Star elements for celebration */}
          {[...Array(50)].map((_, index) => {
            const colors = ["#ffcc00", "#ff6600", "#ff0000"]; // Add more colors as needed
            const randomColor = colors[Math.floor(Math.random() * colors.length)];
      
            return (
              <div
                key={index}
                className="star"
                style={{
                  left: `${Math.random() * window.innerWidth}px`,
                  top: `${Math.random() * window.innerHeight}px`,
                  animationDuration: `${Math.random() * 2 + 1}s`,
                  animationDelay: `${Math.random()}s`,
                  backgroundColor: randomColor,
                }}
              ></div>
            );
          })}
        </div>
          ) : null}
          <div className="text-container">
            {characters.map((character, index) => (
              <span
                key={index}
                className={`falling-character ${character.active ? "" : "inactive"}`}
                style={{ left: `${character.xPosition}%`, top: `${character.yPosition}%` }}
              >
                {character.character}
              </span>
            ))}
          </div>
          <p>Score: {score}</p>
          <HealthBar width={health} />
        </>
      )}
    </div>
  );
};

export default TypingGame;
