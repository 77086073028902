import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useTitle } from '../../Utils/useTitle';

export default function NotFound() {
  useTitle("Noori Computer Classes || Not Found Page")
  return (
    <Box textAlign="center"  py={10} px={6}>
      <Heading
        paddingTop={"100px"}
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text">
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
        The page you're looking for does not seem to exist
      </Text>

      <Button
        as={'a'}
        href={'/'}
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid">
        Go to Home
      </Button>
    </Box>
  );
}