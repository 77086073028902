import {
    Box,
    Center,
    Text,
    Stack,
    List,
    ListItem,
    ListIcon,
    useColorModeValue,
    Flex,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import bgimg from '../../../Assests/picbg.jpg'
import { useState } from 'react';
import ReactTypingSpeedTest from '@skpts/react-typing-speed-test'
import ProceedQuiz from '../../Quiz/ProceedQuiz';
import ProceedTyping from './ProceedTyping';
export default function TypingCard({ title, description, time , data }) {
    const [startTest, setstartTest] = useState(false)
    return (
        <Center py={6}>
            <Box
                maxW={'330px'}
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                boxShadow={'2xl'}
                rounded={'md'}
                overflow={'hidden'}>
                <Stack
                    textAlign={'center'}
                    p={6}
                    color={useColorModeValue('gray.800', 'white')}
                    backgroundImage={bgimg}
                    bgPosition={'center'}
                    align={'center'}
                >
                    <Text
                        fontSize={'sm'}
                        fontWeight={500}
                        bg={useColorModeValue('green.50', 'green.900')}
                        p={2}
                        px={3}
                        color={'green.500'}
                        rounded={'full'}>
                        Typing Test
                    </Text>
                    <Stack direction={'row'} align={'center'} justify={'center'}>

                    </Stack>
                </Stack>

                <Box bg={useColorModeValue('gray.50', 'gray.900')} px={6} py={10}>
                    <List spacing={3}>
                        <ListItem>
                            <Flex><ListIcon as={CheckIcon} color="green.400" />
                            <Flex><b style={{width:"80px"}}>Topic :-</b> <span> {title}</span></Flex> 
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex><ListIcon as={CheckIcon} color="green.400" />
                            <Flex><b style={{width:"150px"}}>Description&nbsp;:-</b> <span><Text noOfLines={2}>&nbsp;{description}</Text></span></Flex> 
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex width='100%' alignItems={'center'}><ListIcon as={CheckIcon} color="green.400" />
                            <Flex width='100%' justifyContent={'space-between'}><Box><b> Total Time :-</b></Box> <Box> {time} Minutes</Box></Flex> 
                            </Flex>
                        </ListItem>
                    </List>
                      <ProceedTyping title={title}  description={description} time={time} data={data}/>
                </Box>
            </Box>
        </Center>
    );
}