import { Box, Heading } from '@chakra-ui/react'
import React from 'react'

const Download = () => {
  return (
    <Box bgColor={'white'} >
    <Heading>Certificates</Heading>
     <>Will be Updated Soon...</>
    </Box>
    
    )
}

export default Download