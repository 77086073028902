import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Center, SimpleGrid, background } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import LoadingSpinner from '../../Components/Loading'
import { useTitle } from '../../Utils/useTitle'




const GalleryPage = () => {
  
  useTitle("Noori Computer Classes || Gallery")
  return (
    <div style={{ paddingTop: "100px" }}>
       
       <Center fontFamily={'cursive'} color={'red'} fontSize={"20"} fontWeight={"800"}>Welcome To Gallery Page</Center>
  <Accordion defaultIndex={[0]} allowMultiple padding={10}>
  <AccordionItem >
    <h2>
      <AccordionButton _hover={()=>{}}  bgGradient={'linear(to-r, red.500, green.500)'} >
        <Box as="span" flex='1' textAlign='left' color={"white"}>
          Teacher Day Programm
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <LoadingSpinner/>
    </AccordionPanel>
  </AccordionItem>
  
  <AccordionItem>
    <h2>
      <AccordionButton _hover={()=>{}} bgGradient={'linear(to-r, red.500, green.500)'} color='white'>
        <Box as="span" flex='1' textAlign='left'>
          Tour Photo
        </Box>
        <AccordionIcon/>
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
        <LoadingSpinner/>
      </AccordionPanel>
  </AccordionItem>
</Accordion>
     
    </div>
  )
}

export default GalleryPage