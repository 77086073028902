import {
   applyMiddleware,
   combineReducers,
   compose,
   legacy_createStore as createStore,
} from "redux";
import { authReducer } from "./Auth/reducer";
import { questionReducer } from "./QuizQuestion/reducer";
import thunk from "redux-thunk";



const rootReducer = combineReducers({
   auth: authReducer,
   question: questionReducer
});


const composeEnhancers =
   typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(rootReducer, enhancer);
