import Toast from "noori-toast";
import { deleteFromLocalStorage, saveToLocalStorage } from "../../Utils/localStorage";
import { SET_AUTH, LOGOUT_USER, LOGIN_ERROR, LOGIN_REQUEST } from "./actionTypes";

export const setAuthCreator = (userData) => async(dispatch)=> {
   
  try{ 
   dispatch({type:LOGIN_REQUEST});
   const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/login`,{
      method:"POST",
      headers:{
         "Content-Type":"Application/json",
      },
      body:JSON.stringify(userData)
   })
   const jsonData = await data.json();
   if(jsonData?.accessToken){
      saveToLocalStorage(`araoalae`,jsonData.role);
      saveToLocalStorage(`taoakaean`,jsonData.accessToken);
      Toast("Login Sucess","success")
      dispatch({ type: SET_AUTH, payload:jsonData})
   }
   else{
      dispatch({type:LOGIN_ERROR})
      Toast(jsonData?.msg||"Server Error","error","top-center")
   }}
   catch(error){
      dispatch({type:LOGIN_ERROR})
      Toast(error,"error","top-center")
   }
};

export const logoutUser = () => {
   deleteFromLocalStorage('araoalae');
   deleteFromLocalStorage('taoakaean');
   return {
   type: LOGOUT_USER,
}
};
