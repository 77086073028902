import {
    Box,
    Button,
    Divider,
    Heading,
    List,
    ListIcon,
    ListItem,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { FaCheckCircle } from 'react-icons/fa';
  import { useNavigate } from 'react-router-dom';
  const optionsadca = [
    { id: 1, desc: 'Basic Knowledge of Computer' },
    { id: 2, desc: 'MS Office Fundamental' },
    { id: 3, desc: 'Corel Draw' },
    { id: 4, desc: 'Photoshop' },
    { id: 5, desc: 'Tally' },
    { id: 6, desc: 'Internet and Networking Basic' },
  ];
  const optionsdca = [
    { id: 1, desc: 'Basic Knowledge of Computer' },
    { id: 2, desc: 'MS Office Fundamental' },
    { id: 3, desc: 'Internet and Networking Basic' },
  ];
  const optionswebs = [
    { id: 1, desc: 'Basic Knowledge of Computer' },
    { id: 2, desc: 'HTML' },
    { id: 3, desc: 'CSS' },
    { id: 4, desc: 'JAVASCRIPT' },
  ];
  const optionsweba = [
    { id: 1, desc: 'Basic Knowledge of Computer' },
    { id: 2, desc: 'HTML' },
    { id: 3, desc: 'CSS' },
    { id: 4, desc: 'JAVASCRIPT' },
    { id: 5, desc: 'MongoDb' },
    { id: 6, desc: 'Express' },
    { id: 7, desc: 'React' },
    { id: 8, desc: 'NodeJs' },

  ];
  const PackageTier = ({
    title,
    options,
    typePlan,
    checked = false,
  }) => {
    const colorTextLight = checked ? 'white' : 'purple.600';
    const bgColorLight = checked ? 'purple.400' : 'gray.300';
  
    const colorTextDark = checked ? 'white' : 'purple.500';
    const bgColorDark = checked ? 'purple.400' : 'gray.300';
    const navigate=useNavigate();
    return (
      <Stack
        p={3}
        py={3}
        justifyContent={{
          base: 'flex-start',
          md: 'space-around',
        }}
        direction={{
          base: 'column',
          md: 'row',
        }}
        alignItems={{ md: 'center' }}>
        <Heading size={'md'} width={"100px"}>{title}</Heading>
        <List spacing={3} textAlign="start">
          {options.map((desc, id) => (
            <ListItem key={desc.id}>
              <ListIcon as={FaCheckCircle} color="green.500" />
              {desc.desc}
            </ListItem>
          ))}
        </List>
        <Heading size={'md'}>{typePlan}</Heading>
        <Stack>
          <Button
            onClick={()=>navigate('/contact')}
            size="md"
            color={useColorModeValue(colorTextLight, colorTextDark)}
            bgColor={useColorModeValue(bgColorLight, bgColorDark)}>
            Get Started
          </Button>
        </Stack>
      </Stack>
    );
  };
  const Courses = () => {
    return (
      <Box py={6} px={5} min={'100vh'}>
        <Stack spacing={4} width={'100%'} direction={'column'}>
          <Stack
            p={5}
            alignItems={'center'}
            justifyContent={{
              base: 'flex-start',
              md: 'space-around',
            }}
            direction={{
              base: 'column',
              md: 'row',
            }}>
            <Stack
              width={{
                base: '100%',
                md: '40%',
              }}
              textAlign={'center'}>
              <Heading size={'lg'}>
                The Right Courses for <Text color="purple.400">Your Future</Text>
              </Heading>
            </Stack>
            <Stack
              width={{
                base: '100%',
                md: '60%',
              }}>
              <Text textAlign={'center'}>
              Choosing the right courses for your future is important as it sets the foundation
              for your career path and personal growth. Consider online courses, internships, 
              and workshops to gain practical experience in your field of interest. Taking the right
              courses can help you stand out in the job market, develop a strong skill set,
              and increase your chances of success in the future.
              </Text>
            </Stack>
          </Stack>
          <Divider />
          <PackageTier
            title={'DCA'}
            options={optionsdca}
            typePlan="6 Months"
          />
          <Divider />
          <PackageTier checked={true} title={'ADCA'} typePlan="1 Year" options={optionsadca} />
          <Divider />
          <PackageTier title={'Web Designing Starter'} typePlan="4 Month" options={optionswebs} />
          <Divider />
          <PackageTier title={'Web Designing Advance'} typePlan="9 Month" options={optionsweba} />
        </Stack>
      </Box>
    );
  };
  
  export default Courses;