import React from 'react'
import styles from "./userCreate.module.css"
import {useState} from 'react'
import axios from "axios";
import { Button,useToast } from '@chakra-ui/react'
 

const defaultData={
  name: "",
  role: "user",
  email: "",
  phone: 0,
  fatherName : "",
  motherName:"",
  dateOfAddmission : "",
  dateOfBirth : "" ,
  regNumber : "",
  rollNo : 0,
  course : "",
  address:""
 }
const UserUpdate = () => {
    const [loading,setloading]=useState(false)
    let tost=useToast();
   const [userData,setuserData]=useState(defaultData)

   async function handlesave(event){
    event.preventDefault();
    setloading(true)
    try{
      let update = await axios.post(`${process.env.REACT_APP_BACKEND_URL || "https://ncbackend.onrender.com"}/admin/createuser`, userData);
      if(update.data.error){
        tost({
          title: "Info",
          description: "User Already Exist",
          status: "error",
          duration: 3000,
          isClosable: true,
          position:"top"
        })
        setloading(false)
      }
      else{
        setuserData(defaultData)
        tost({
            title: "Info",
            description: "User Created Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position:"top"
          })
          setloading(false)
      }
    
    }
    catch(err){
      alert("Something went wrong! Try Again")
      console.log(err)
      setloading(false)
    }
    
   }


  return (
    <>
      <div className={styles.maindiv}>
        <div className={styles.rightdiv}>
          <div className={styles.subheading}>Admission details :-</div><br/>
          <div className={styles.rightmain}>
            <form className={styles.form} onSubmit={handlesave}>
              <div>
                <label for="email">
                  <span className={styles.spanName}>Email</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="email" value={userData.email}  className={styles.input} 
                  onChange={(e)=>setuserData({...userData,email:e.target.value})}
                  />
                </div>
              </div>
              <div>
                <label for="course">
                  <span className={styles.spanName}>Course</span>
                  <span className={styles.star}></span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.course}  className={styles.input} 
                  onChange={(e)=>setuserData({...userData,course:e.target.value})}
                  />
                </div>
              </div>
              <div>
                <label for="regdnumber">
                  <span className={styles.spanName}>Registration No.</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.regNumber} className={styles.input} 
                  onChange={(e)=>setuserData({...userData,regNumber:e.target.value})}
                  />
                </div>
              </div>
              <div>
                <label for="rollnumber">
                  <span className={styles.spanName}>Roll No.</span>
                  <span className={styles.star}></span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="number" value={userData.rollNo}  className={styles.input} 
                  onChange={(e)=>setuserData({...userData,rollNo:+e.target.value})}
                  />
                </div>
              </div>
              <div>
                <label for="name">
                  <span className={styles.spanName}>Name</span>
                  <span className={styles.star}></span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.name} placeholder={"Enter your name"} 
                  onChange={(e)=>setuserData({...userData,name:e.target.value})} 
                  className={styles.input} />
                </div>
              </div>
              <div>
              <div>
                <label for="fname">
                  <span className={styles.spanName}>Father Name</span>
                  <span className={styles.star}></span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.fatherName} placeholder={"Enter your Father name"} 
                  onChange={(e)=>setuserData({...userData,fatherName:e.target.value})} className={styles.input} />
                </div>
              </div>
                <label for="mname">
                  <span className={styles.spanName}>Mother Name</span>
                  <span className={styles.star}></span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.motherName} placeholder={"Enter your Mother name"} 
                  onChange={(e)=>setuserData({...userData,motherName:e.target.value})} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="dob">
                  <span className={styles.spanName}>Date of Birth</span>
                  <span className={styles.star}></span>
                </label>
                <div className={styles.inputdiv}>
                  <input type='date'  value={userData.dateOfBirth}
                   onChange={(e)=>setuserData({...userData,dateOfBirth:e.target.value})} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="mobilenumber">
                  <span className={styles.spanName}>Mobile Number</span>
                  <span className={styles.star}></span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="number" value={userData.phone} placeholder={"Enter you number"}
                   onChange={(e)=>setuserData({...userData,phone:Number(e.target.value)})} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="address">
                  <span className={styles.spanName}>Full Address</span>
                  <span className={styles.star}></span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.address} placeholder={"Enter you full Address"}
                   onChange={(e)=>setuserData({...userData,address:e.target.value})} className={styles.input} />
                </div>
              </div>

              <Button isLoading={loading} variant={'solid'} type="submit">Add User</Button>&nbsp;&nbsp;&nbsp;&nbsp;
              
            </form>
          </div>
        </div>
      </div>
    </>

  )
}

export default UserUpdate
