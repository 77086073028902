import {
    Box,
    Center,
    Text,
    Stack,
    List,
    ListItem,
    ListIcon,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { CheckIcon } from '@chakra-ui/icons';
  import bgimg from '../../Assests/picbg.jpg'
import ProceedQuiz from './ProceedQuiz';
  
  export default function QuizCard({ title,description, totalMarks, time, _id, password}) {
    return (
      <Center py={6}>
        <Box
          maxW={'330px'}
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}>
          <Stack
            textAlign={'center'}
            p={6}
            color={useColorModeValue('gray.800', 'white')}
            backgroundImage={bgimg}
            bgPosition={'center'}
            align={'center'}
            >
            <Text
              fontSize={'sm'}
              fontWeight={500}
              bg={useColorModeValue('green.50', 'green.900')}
              p={2}
              px={3}
              color={'green.500'}
              rounded={'full'}>
              Mock Quiz
            </Text>
            <Stack direction={'row'} align={'center'} justify={'center'}>
              
            </Stack>
          </Stack>
  
          <Box bg={useColorModeValue('gray.50', 'gray.900')} px={6} py={10}>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={CheckIcon} color="green.400" />
                  <b>Topic :-</b> {title}
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="green.400" />
                <b>Description :-</b> {description}
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="green.400" />
                <b>Total Time :- </b> {time} Minutes
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="green.400" />
               <b> Total Marks :-</b> {totalMarks} 
              </ListItem>
            </List>
            <ProceedQuiz _id={_id} title={title} time={time} password={password} />
          </Box>
        </Box>
      </Center>
    );
  }