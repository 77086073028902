import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Flex, SimpleGrid, background } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import LoadingSpinner from '../../../Components/Loading'
import DemoData from './typingData.json'
import TypingCard from './TypingCard'
import { useTitle } from '../../../Utils/useTitle'
import typingimg from '../../../Assests/falling.gif';
import { Navigate, useNavigate } from 'react-router-dom'

const TypingTest = () => {
  const navigate=useNavigate();
  useTitle("Noori Computer Classes || Typing Test");

  return (
    <div style={{ paddingTop: "100px" }}>
       
  <Center fontFamily={'cursive'} color={'rgb(224,41,135)'} fontSize={"20"} fontWeight={"800"}>Play Typing Game</Center>
  <Accordion defaultIndex={[0]} allowMultiple padding={10}>
  <AccordionItem >
    <h2>
      <AccordionButton _hover={()=>{}}  bgGradient={'linear(to-r, rgb(18,86,105), rgb(224,41,135))'} >
        <Box as="span" flex='1' textAlign='left' color={"white"}>
          English Typing Game
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4} >
        <Flex   backgroundPosition="center"  backgroundRepeat="no-repeat"  borderRadius={5} w={200} h={200} border={"1px solid black"} alignItems={"center"} justifyContent={"center"}>
          <Button colorScheme='red' onClick={()=>navigate('/typinggame')}>Let Start</Button> 
         </Flex>
    </AccordionPanel>
  </AccordionItem>
  
  <AccordionItem>
    <h2>
      <AccordionButton _hover={()=>{}} bgGradient={'linear(to-r, rgb(18,86,105), rgb(224,41,135))'} color='white'>
        <Box as="span" flex='1' textAlign='left'>
           Hindi Typing Game - Coming soon
        </Box>
        <AccordionIcon/>
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
        <LoadingSpinner/>
      </AccordionPanel>
  </AccordionItem>
</Accordion>
  <Center fontFamily={'cursive'} color={'rgb(224,41,135)'} fontSize={"20"} fontWeight={"800"}>Know your Typing Speed</Center>
  <Accordion allowMultiple padding={10}>
  <AccordionItem >
    <h2>
      <AccordionButton _hover={()=>{}}  bgGradient={'linear(to-r, rgb(18,86,105), rgb(224,41,135))'} >
        <Box as="span" flex='1' textAlign='left' color={"white"}>
          English Typing Test
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4} >
      <SimpleGrid columns={{lg:3,md:2,base:1}} spacing={10} margin={"5px"}>
          {DemoData && DemoData.map((ele,index)=><TypingCard key={index+ele.title} {...ele}/>)}
      </SimpleGrid>
    </AccordionPanel>
  </AccordionItem>
  
  <AccordionItem>
    <h2>
      <AccordionButton _hover={()=>{}} bgGradient={'linear(to-r, rgb(18,86,105), rgb(224,41,135))'} color='white'>
        <Box as="span" flex='1' textAlign='left'>
           Hindi Typing Test - Coming soon
        </Box>
        <AccordionIcon/>
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
        <LoadingSpinner/>
      </AccordionPanel>
  </AccordionItem>
</Accordion>
     
    </div>
  )
}

export default TypingTest