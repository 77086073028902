import { Box, Button, Center, Flex, Grid, GridItem } from '@chakra-ui/react'
import React, { useEffect, useId, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getQuizData } from '../../Redux/QuizQuestion/action'
import { deleteFromLocalStorage, getLocalStorageData, saveToLocalStorage } from '../../Utils/localStorage'
import Pagination from './Pagination'
import SingleQuestion from './SingleQuestion'

const QuizQuestionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([])
  const [filterObject, setfilterObject] = useState({})
  const { id } = useParams();
  const [time, setTime] = useState(0)
  const [second, setSecond] = useState(60)


  const { questionBank } = useSelector(store => store.question);

  //dispatch getdata if page is loaded or refresh
  useEffect(() => {

    if (questionBank.length === 0) {
      dispatch(getQuizData())
    }
  }, [])

  //removing previous attempt from localstorage if page refresh
  useEffect(() => {
    setTime(getLocalStorageData(filterObject?._id)?.time || filterObject?.time)
    setSecond(getLocalStorageData(filterObject?._id)?.second || 0)
  }, [filterObject])

  //filtering the quiz
  useEffect(() => {
    const questionNo = searchParams.get("questionNo")
    const filterData = questionBank?.find((el) => el._id == id)
    setfilterObject(filterData)
    setData(filterData?.questions[questionNo - 1])

  }, [location.key, questionBank])


  useEffect(() => {
    let id2 = setInterval(() => {
      if (second === 0) {
        setSecond(60);
        let temp = getLocalStorageData(filterObject?._id);
        saveToLocalStorage(filterObject?._id, { ...temp, time: time - 1, })
        setTime(time => time - 1);
      }
      else {
        let temp = getLocalStorageData(filterObject?._id);
        saveToLocalStorage(filterObject?._id, { ...temp,second:second-1 })
        setSecond(second => second - 1)
      }
    }, 1000);
    if (time == 0 && second == 0) {
      navigate(`/quiz/quizresult/${filterObject?._id}`)
    }

    return () => clearInterval(id2)
  }, [second])
  if (!getLocalStorageData(id)) {
    return <Navigate to='/quiz' />
  }

  return (
    <Box style={{ paddingTop: "100px" }} bgGradient='linear(to-b, teal.300, green.200)'>
      <Flex boder='1px solid red' justifyContent={'center'} paddingBottom={10} alignItems={'center'} direction={'column'}>
        <SingleQuestion data={data && data} filterObject={filterObject} time={time} second={second} />
      </Flex>
    </Box>
  )
}

export default QuizQuestionPage





