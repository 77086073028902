let lastToast = null;

const iconTypeMap = {
  information: "info",
  error: "error",
  warning: "warning",
  success: "check_circle",
  simple: "",
};

const positionStyles = {
  "top-left": {
    top: "40px",
    left: "10px",
  },
  "top-center": {
    top: "40px",
    left: "calc(50% - 240px)",
  },
  "top-right": {
    top: "40px",
    right: "10px",
  },
  "bottom-left": {
    bottom: "10px",
    left: "10px",
  },
  "bottom-center": {
    bottom: "10px",
    left: "calc(50% - 240px)",
  },
  "bottom-right": {
    bottom: "10px",
    right: "10px",
  },
  default: {
    top: "40px",
    left: "calc(50% - 240px)",
  },
};

function createToastElement() {
  const toastDiv = document.createElement("div");
  toastDiv.style.position = "fixed";
  toastDiv.style.zIndex = "9999";
  toastDiv.style.width = "480px";
  toastDiv.style.height = "48px";
  toastDiv.style.color = "white";
  toastDiv.style.fontSize = "14px";
  toastDiv.style.fontWeight = "bold";
  toastDiv.style.display = "flex";
  toastDiv.style.justifyContent = "space-between";
  toastDiv.style.alignItems = "center";
  toastDiv.style.padding = "6px";
  toastDiv.style.borderRadius = "8px";
  toastDiv.style.boxSizing = "border-box";

  return toastDiv;
}

function setToastPosition(toastDiv, position) {
  const styles = positionStyles[position] || positionStyles.default;
  for (let [key, value] of Object.entries(styles)) {
    toastDiv.style[key] = value;
  }
}

function setToastBackgroundColor(toastDiv, type) {
  const backgroundColors = {
    information: "#3470E4",
    error: "#D61E27",
    success: "#049402",
    warning: "#FF832B",
    simple: "#0A0103",
  };

  toastDiv.style.backgroundColor = backgroundColors[type];
}

function createIconElement(type) {
  const icon = document.createElement("span");
  icon.classList.add("material-icons");
  icon.style.paddingRight = "10px";

  if (iconTypeMap.hasOwnProperty(type)) {
    icon.innerText = iconTypeMap[type];
  } else {
    icon.innerText = "";
  }

  return icon;
}

function createTextElement(title) {
  const text = document.createElement("span");
  text.classList.add("truncate");
  text.style.textAlign = "left";
  text.innerText = title;

  return text;
}

function createCloseButton() {
  const closeButton = document.createElement("button");
  closeButton.classList.add("close-button");
  closeButton.innerHTML = "&times;";
  closeButton.style.marginLeft = "auto";
  closeButton.style.border = "none";
  closeButton.style.background = "transparent";
  closeButton.style.color = "white";
  closeButton.style.fontSize = "24px";
  closeButton.style.fontWeight = "bold";

  return closeButton;
}

export default function Toast(title, type = "error", position = "top-center") {
  const toastDiv = createToastElement();
  setToastPosition(toastDiv, position);
  setToastBackgroundColor(toastDiv, type);

  const icon = createIconElement(type);
  toastDiv.appendChild(icon);

  const text = createTextElement(title);
  toastDiv.appendChild(text);

  const closeButton = createCloseButton();
  closeButton.addEventListener("click", () => {
    if (document.body.contains(toastDiv)) {
      document.body.removeChild(toastDiv);
    }
    if (lastToast === toastDiv) {
      lastToast = null;
    }
  });

  toastDiv.appendChild(closeButton);

  if (lastToast) {
    const lastToastRect = lastToast.getBoundingClientRect();
    const top = lastToastRect.bottom + 10;
    const left = lastToastRect.left;
    toastDiv.style.top = top + "px";
    toastDiv.style.left = left + "px";
  }

  document.body.appendChild(toastDiv);
  lastToast = toastDiv;

  const timeout = setTimeout(() => {
    if (document.body.contains(toastDiv)) {
      document.body.removeChild(toastDiv);
      if (lastToast === toastDiv) {
        lastToast = null;
      }
    }
  }, 3000);

  return () => {
    clearTimeout(timeout);
    if (document.body.contains(toastDiv)) {
      document.body.removeChild(toastDiv);
      if (lastToast === toastDiv) {
        lastToast = null;
      }
    }
  };
}
