import { useSelector } from 'react-redux';
import './App.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import AllRoutes from './Routes/AllRoutes';

function App() {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <>
    {isAuth?null:<Navbar/>}
    <AllRoutes/>
    {isAuth?null:<Footer/>}
    </>
  );
}

export default App;
