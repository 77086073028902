import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from '../Pages/commonSide/Contact'
import HomePage from '../Pages/commonSide/HomePage'
import Login from '../Pages/commonSide/Login/Login'
import NotFound from '../Pages/commonSide/NotFound'
import { PublicRoute } from './PublicRoute'
import UserRoute from './UserRoute'
import AdminRoute from './AdminRoute'
import VerifyCertificate from '../Pages/commonSide/VerifyCertificate';
import QuizHome from '../Pages/Quiz/QuizHome'
import QuizQuestionPage from '../Pages/Quiz/QuizQuestionPage'
import QuizResult from '../Pages/Quiz/QuizResult'
import GalleryPage from '../Pages/commonSide/Gallery'
import TypingTest from '../Pages/commonSide/typingTest/TypingTest'
import TypingPage from '../Pages/commonSide/typingTest/TypingPage'
import TypingGame from '../Pages/commonSide/typingTest/TypingGame/TypingGame'
import SendOtp from '../Pages/commonSide/Login/SendOtp'

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<PublicRoute><HomePage /></PublicRoute>} />
      <Route path='/*' element={<NotFound />} />
      <Route path='/sendotp' element={<SendOtp/>} />
      <Route path='/login' element={<PublicRoute><Login /></PublicRoute>} />
      <Route path='/contact' element={<PublicRoute><Contact /></PublicRoute>} />
      <Route path='/gallery' element={<PublicRoute><GalleryPage/></PublicRoute>} />
      <Route path='/quiz' element={<PublicRoute><QuizHome /></PublicRoute>} />
      <Route path='/typingtest' element={<PublicRoute><TypingTest/></PublicRoute>} />
      <Route path='/typingpage' element={<PublicRoute><TypingPage/></PublicRoute>} />
      <Route path='/typinggame' element={<PublicRoute><TypingGame/></PublicRoute>} />
      <Route path='quiz/:title/:id' element={<PublicRoute><QuizQuestionPage /></PublicRoute>} />
      <Route path='quiz/quizresult/:id' element={<PublicRoute><QuizResult/></PublicRoute>} />
      <Route path='/verifyCertificate' element={<PublicRoute><VerifyCertificate></VerifyCertificate></PublicRoute>} />
      <Route path='/admin/*' element={<AdminRoute />} />
      <Route path='/user/*' element={<UserRoute />} />
    </Routes>
  )
}

export default AllRoutes