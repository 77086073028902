import {useDisclosure,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
   ModalFooter,
   FormControl,
   FormErrorMessage,
   FormHelperText,
   FormLabel,
   Input} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteFromLocalStorage, getLocalStorageData, saveToLocalStorage } from '../../../Utils/localStorage';
//import { deleteFromLocalStorage, getLocalStorageData, saveToLocalStorage } from '../../Utils/localStorage';
import Toast from '../../../Components/Toast.js'
export default function ProceedTyping({ title, description, time , data }) {
const { isOpen, onOpen, onClose } = useDisclosure();
const [input, setInput] = useState({name:"",email:""});
const navigate=useNavigate();
const isErrorname = input?.name === '';
const isErroremail = input?.email === '';
const handleProceed=()=>{
   if(input?.email!="" && input?.name!="" ){
        let check=getLocalStorageData("typingData");
        if(!check){
            deleteFromLocalStorage("typingData")
        }
       let name=input?.name
       saveToLocalStorage("typingData",{data,time,name})
       navigate(`/typingpage`)
   }
   else{
       Toast("Please enter all details","error","top-center")
   }
}

return (
 <>
   <Button mt={10}
         w={'full'}
         bg={'rgb(237,100,166)'}
         color={'white'}
         rounded={'xl'}
         boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
         _hover={{
           bg: 'pink.300',
         }}
         _focus={{
           bg: 'pink.300',
         }}
         onClick={onOpen}>Attempt</Button>

   <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} >
     <ModalOverlay />
     <ModalContent marginTop={100}>
       <ModalHeader>Enter your details to proceed</ModalHeader>
       <ModalCloseButton />
       <FormControl isInvalid={isErroremail}>
       <ModalBody pb={6}>
       
               <FormLabel>Name</FormLabel>
               <Input type='text' value={input?.name} onChange={(e)=>setInput({...input,name:e.target.value})} />
                   {!isErrorname ? (
               <FormHelperText> Enter your correct name</FormHelperText>
                   ) : (
                    <FormErrorMessage>Name is required.</FormErrorMessage>
                   )}
               <FormLabel>Email</FormLabel>
               <Input type='text' value={input?.email} onChange={(e)=>setInput({...input,email:e.target.value})} />
                   {!isErroremail ? (
               <FormHelperText> Enter correct Email Id</FormHelperText>
                   ) : (
                    <FormErrorMessage>Email Id Code is required.</FormErrorMessage>
                   )}
       </ModalBody>

       <ModalFooter>
       <Button
         mt={10}
         w={'half'}
         bg={'green.400'}
         color={'white'}
         rounded={'xl'}
         boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
         _hover={{
           bg: 'green.500',
         }}
         _focus={{
           bg: 'green.500',
         }}
         onClick={handleProceed}
         >Proceed
       </Button>
         <Button
         mt={10}
         ml={5}
         w={'half'}
         bg={'orange.400'}
         color={'white'}
         rounded={'xl'}
         boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
         _hover={{
           bg: 'orange.500',
         }}
         _focus={{
           bg: 'orange.500',
         }}
          onClick={onClose}>Cancel</Button>
       </ModalFooter>
       </FormControl>
     </ModalContent>
   </Modal>
 </>
)
}