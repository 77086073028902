import React from 'react'
import {Route,Routes} from 'react-router-dom'
import AdminDashboard from '../Pages/adminSide/Dashboard'
import { PrivateRoute } from './PrivateRoute'
import Liveexam from '../Pages/userSide/Liveexam'
import Download from '../Pages/userSide/Download'
import AdminProfile from '../Pages/adminSide/profile/Profile'
import UserTabs from '../Pages/adminSide/User/UserTabs'
import AdminHome from '../Pages/adminSide/Home'
const AdminRoute = () => {
  return (
    <AdminDashboard>
    <Routes>
            <Route path='/dashboard' element={<PrivateRoute><AdminHome/></PrivateRoute>}/>
            <Route path='/profile' element={<PrivateRoute><AdminProfile/></PrivateRoute>}/>
            <Route path='/liveexam' element={<PrivateRoute><Liveexam/></PrivateRoute>}/>
            <Route path='/userdetails' element={<PrivateRoute><UserTabs/></PrivateRoute>}/>
            <Route path='/download' element={<PrivateRoute><Download/></PrivateRoute>}/>
    </Routes>
    </AdminDashboard>
  )
}

export default AdminRoute