import React from 'react'
import styles from "./Profile.module.css"
import {useState} from 'react'
import { Button, Spinner } from '@chakra-ui/react'
import Toast from 'noori-toast'
const UserProfile = ({data,loading,token}) => {
   const [userData,setUserData]=useState(data);
   const [toggleupdate,setToggleUpdate]=useState(true)

   const handleUpdate = async () => {
    try { 
      const userToken = token; // Replace with the actual user token

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/updateuserbyuser`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userToken,
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        // Handle successful update, e.g., show a success message
        Toast('User details updated successfully',"success",'top-center');
        setToggleUpdate(true);
      } else {
        // Handle error response, e.g., show an error message
        
        Toast('Failed to update user details','error','top-center');
      }
    } catch (error) {
      console.error('Error updating user details', error);
    }
  };
  return loading && !data ? <Spinner/> : (
    <>
      <div className={styles.maindiv}>
        <div className={styles.rightdiv}>
          <div className={styles.subheading}>My Profile</div><br/>
          <div className={styles.rightmain}>
            <form className={styles.form}>
              <div>
                <label for="email">
                  <span className={styles.spanName}>Email</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.email} disabled={true} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="batch">
                  <span className={styles.spanName}>Batch</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.batch} disabled={true} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="regdnumber">
                  <span className={styles.spanName}>Registration No.</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.regNumber} disabled={true} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="rollnumber">
                  <span className={styles.spanName}>Roll No.</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={userData.rollNo} disabled={true} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="name">
                  <span className={styles.spanName}>Name</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" disabled={toggleupdate} placeholder={userData?.name?userData.name:"Enter your name"} onChange={(e)=>setUserData({...userData,username:e.target.value})} className={styles.input} />
                </div>
              </div>
              <div>
              <div>
                <label for="fname">
                  <span className={styles.spanName}>Father Name</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" disabled={toggleupdate} 
                  placeholder={userData.fatherName?userData.fatherName:"Enter your Father name"} 
                  onChange={(e)=>setUserData({...userData,fatherName:e.target.value})} className={styles.input} />
                </div>
              </div>
                <label for="mname">
                  <span className={styles.spanName}>Mother Name</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" disabled={toggleupdate}
                   placeholder={userData.motherName?userData.motherName:"Enter your Mother name"}
                    onChange={(e)=>setUserData({...userData,motherName:e.target.value})} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="dob">
                  <span className={styles.spanName}>Date of Birth</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type='date' disabled={true}
                   value={userData?.dateOfBirth}
                   className={styles.input} />
                </div>
              </div>
              <div>
                <label for="mobilenumber">
                  <span className={styles.spanName}>Mobile Number</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="number" disabled={toggleupdate} placeholder={userData.phone?userData.phone:"Enter you number"} 
                  onChange={(e)=>setUserData({...userData,phone:Number(e.target.value)})} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="address">
                  <span className={styles.spanName}>Full Address</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" disabled={toggleupdate} 
                   placeholder={userData.address?userData.address:"Enter you full Address"}
                   onChange={(e)=>setUserData({...userData,address:e.target.value})} className={styles.input} />
                </div>
              </div>

              {toggleupdate?<Button variant={'solid'} colorScheme={'teal'} onClick={()=>setToggleUpdate(false)}>Edit</Button>:
              <>
              <Button variant={'solid'} colorScheme={'teal'} onClick={handleUpdate}>Update</Button>&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant={'solid'} onClick={()=>setToggleUpdate(true)} colorScheme={'orange'}>Cancel</Button>
              </>}
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProfile
