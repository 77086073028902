import {
    Box,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
  } from '@chakra-ui/react'
  
  import { useState } from 'react'
import { Link } from 'react-router-dom'
import { InitialFocus } from './PasswordReset'
  
  const SendOtp = () => {
    
    const [userData,setUserData]=useState({email:"",resetPassword:true});
    
    return <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
  
          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            <Heading
              size={{
                base: 'xs',
                md: 'sm',
              }}
            >
              Reset Your Password
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg-surface',
          }}
          boxShadow={{
            base: 'none',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="email" placeholder='Enter Registered Email Id' type="email" onChange={(e)=>setUserData({...userData,email:e.target.value})} />
              </FormControl>
              <InitialFocus userData={userData}/>
           </Stack>
            <Stack spacing="6">
                  <br/>
              <Link to={'/login'} style={{color:'red'}} color='red'> Go back to Login</Link>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  }
  
  
  export default SendOtp;