import React from 'react'
import styles from "./Profile.module.css"

import {useState} from 'react'
import axios from "axios";
import { Button } from '@chakra-ui/react'
const AdminProfile = () => {
   const [updata,upsetdata]=useState(JSON.parse(localStorage.getItem('userdetail'))||{});
   const [userdata,setuserdata]=useState({email:updata.email});
   async function handlesave(event){
    event.preventDefault();
    let update = await axios.post("https://pharmeasy-backend.onrender.com/updateUser", userdata);
    localStorage.setItem('userdetail',JSON.stringify(userdata));
    window.location.reload(true)
   }


  return (
    <>
      <div className={styles.maindiv}>
        <div className={styles.rightdiv}>
          <div className={styles.subheading}>EDIT PROFILE</div><br/>
          <div className={styles.rightmain}>
            <form className={styles.form}>
              <div>
                <label for="name">
                  <span className={styles.spanName}>Name</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" placeholder={updata.username?updata.username:"Enter your name"} onChange={(e)=>setuserdata({...userdata,username:e.target.value})} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="mobilenumber">
                  <span className={styles.spanName}>Mobile Number</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="number" placeholder={updata.number?updata.number:"Enter you number"} onChange={(e)=>setuserdata({...userdata,number:Number(e.target.value)})} className={styles.input} />
                </div>
              </div>
              <div>
                <label for="email">
                  <span className={styles.spanName}>Email</span>
                  <span className={styles.star}>*</span>
                </label>
                <div className={styles.inputdiv}>
                  <input type="text" value={updata.email} disabled={true} className={styles.input} />
                </div>
              </div>

              <Button variant={'solid'} colorScheme={'teal'}>Save</Button>
            </form>
          </div>
        </div>
      </div>
    </>

  )
}

export default AdminProfile
