import { Box, Button, Checkbox, Flex, Text, VStack,useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLocalStorageData } from '../../Utils/localStorage';



const Pagination = ({rendercheck}) => {
    const toast = useToast()
    const [searchParam, setSearchParmas] = useSearchParams();
    const [check,setcheck]=useState(false);
    let initialQuestionParams = searchParam.get("questionNo");
    const [activeQuestion, setActiveQuestion] = useState(+initialQuestionParams || 1);
    const navigate=useNavigate();
    const { id } = useParams();
    const [dcolor,setdcolor]=useState({
        red:"red",
        green:"green",
        blue:"blue"
    });
    const [data,setdata]=useState(getLocalStorageData(id)||{});

    const total = useSelector(state => state?.question?.questionBank);
     
    const filterData=total.filter((e)=>e._id==id);

    const totalQuestion =filterData[0]?.questions?.length;

    const handleQuestionPage = (qNo) => {
        setActiveQuestion(qNo)
    }
    useEffect(() => {
        if (activeQuestion) {
            const params = {};
            params.questionNo = activeQuestion
            setSearchParmas(params)
        }
    }, [activeQuestion, setSearchParmas])
   
    useEffect(()=>{
            setdata(getLocalStorageData(id))
    },[activeQuestion])

    return (
        <Flex   
         gap={10}
          margin={0} flexDir={'column'} height={'max-content'} justifyContent='space-between' >

            {/* {Prev Button} */}
            <Flex spacing={3} gap={4} wrap={'wrap'} justifyContent='center'>
            {Array(totalQuestion).fill('').map((item, index) => {
                return <Button borderRadius={100} 
                    colorScheme={data?.attempted?.[filterData[0]?.questions?.[index]?._id]?"green":"red" } onClick={() => handleQuestionPage(index + 1)}
                    key={index}>{index + 1}</Button>
            })

            }
            </Flex>
            <Flex gap={5} justifyContent='center' >
            <Button  color='white'
            fontWeight='bold'
            borderRadius='md'
            bgGradient='linear(to-r, red.500, yellow.500)'
            _hover={{
              bgGradient:'linear(to-r, teal.500, green.500)' ,
            }}
              w={{lg:'100%',sm:"200px"}} marginTop="0px" 
             isDisabled={activeQuestion === 1} 
             onClick={() => handleQuestionPage(activeQuestion - 1)}>Prev</Button>
            <Button
             color='white'
             fontWeight='bold'
             borderRadius='md'
             bgGradient='linear(to-r, red.500, yellow.500)'
             _hover={{
               bgGradient:'linear(to-r, teal.500, green.500)' ,
             }}
             w={{lg:'100%',sm:"200px"}} marginBottom="0" bgColor="orange.400" isDisabled={activeQuestion === totalQuestion} onClick={() => handleQuestionPage(activeQuestion + 1)}>Next</Button>
            </Flex>
            <VStack>
             <Checkbox isChecked={check} onChange={(e)=>setcheck(e.target.checked)}  marginTop={10}><Text color='blue'>I agree to submit</Text></Checkbox>
             <Button w='150px'  marginTop="2px" bgColor="orange.400" 
             onClick={()=>{
                if(check)
                navigate(`/quiz/quizresult/${id}`)
                else{
                    toast({
                        title: `Please Tick I Agree checkbox`,
                        status: 'error',
                        isClosable: true,
                        position:"top"
                      })
                }
                }}>Final Submit</Button>
             </VStack>  
        </Flex>
    )

}

export default Pagination
