export const getLocalStorageData = (key) => {
   try {
      let data = localStorage.getItem(key);
      data = JSON.parse(data);
      return data;
   } catch (e) {
      return undefined;
   }
};

export const saveToLocalStorage = (key, data) => {
   localStorage.setItem(key, JSON.stringify(data));
};

export const deleteFromLocalStorage = (key) => {
   localStorage.removeItem(key);
};
