import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsPerson } from 'react-icons/bs';
import { FiServer, FiBookOpen } from 'react-icons/fi';
function StatsCard(props) {
  const { title, stat, icon } = props;

  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      rounded={'lg'}
      transition="transform 0.2s ease-in-out"
      _hover={{ transform: 'scale(1.05)' }}
      bgGradient="linear(to-r, teal.400, green.500)"
      color="white"
    >
      <Flex
        justifyContent={'space-between'}
        alignItems={'flex-start'} // Align items to the start (top)
      >
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel
            fontWeight={'semibold'}
            fontSize={{ base: 'xl', md: '2xl' }}
            color={useColorModeValue('white', 'gray.800')}
          >
            {title}
          </StatLabel>
          <StatNumber
            fontSize={{ base: 'sm', md: 'md' }}
            fontWeight={'semibold'}
          >
            {stat}
          </StatNumber>
        </Box>
        <Box
          ml={'auto'} // Move the box to the right (auto margin)
          my={'auto'}
          color={useColorModeValue('white', 'gray.800')}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}


export default function UserHome({ loading, data, present }) {
  const [payment, setpayment] = useState(0)
  useEffect(() => {
    if (payment == 0)
      data?.payments?.map((curr) => {
        setpayment(prev => prev + curr.rupees)
      })

  }, [data])
  return (
    <Box maxW="7xl" mx={'auto'} pt={10} px={{ base: 2, sm: 12, md: 17 }} bgColor={'white'} height={'100vh'}>
      {loading && <Spinner />}
      {!loading && data?.batch && <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          title={'Batch'}
          stat={data.batch}
          icon={<BsPerson size={'3em'} />}
        />
        <StatsCard
          title={'Attendence'}
          stat={<> {present} / {data?.attendance?.length} </>}
          icon={<FiServer size={'3em'} />}
        />
        <StatsCard
          title={'Admission'}
          stat={`${data?.dateOfAddmission}`}
          icon={<FiBookOpen size={'3em'} />}
        />
        <StatsCard
          title={'Payment Due'}
          stat={payment ? data?.totalFee - payment : data?.totalFee}
          icon={<FiBookOpen size={'3em'} />}
        />
      </SimpleGrid>}
      <br />
      {!loading && data?.payments && (

        <Box mt={10}>
          <Heading fontSize={'md'}>Payments</Heading><br></br>
          <Table
            width="100%"
            borderCollapse="collapse"
            border="1px solid"
            borderColor="teal.400"
            borderRadius="lg"
          >
            <Thead>
              <Tr bgGradient="linear(to-r, teal.400, green.500)" color="white">
                <Th padding="8px" textAlign="left" color={'white'}>
                  Date
                </Th>
                <Th padding="8px" textAlign="left" color="white">
                  Mode
                </Th>
                <Th padding="8px" textAlign="left" color="white">
                  Rupees
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.payments.map((payment) => (
                <Tr key={payment._id} borderBottom="1px solid" borderColor="teal.400">
                  <Td padding="8px" textAlign="left">
                    {payment.date}
                  </Td>
                  <Td padding="8px" textAlign="left">
                    {payment.mode}
                  </Td>
                  <Td padding="8px" textAlign="left">
                    {payment.rupees}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
             )}
    </Box>
  );
}