import React, { useEffect, useState } from 'react'
import {Route,Routes} from 'react-router-dom'
import Profile from '../Pages/userSide/profile/UserProfile'
import UserDashboard from '../Pages/userSide/Dashboard'
import { PrivateRoute } from './PrivateRoute'
import Liveexam from '../Pages/userSide/Liveexam'
import Lecture from '../Pages/userSide/Lecture'
import Download from '../Pages/userSide/Download'
import UserHome from '../Pages/userSide/Home'
import { useSelector } from 'react-redux'
import { getLocalStorageData } from '../Utils/localStorage'
const UserRoute = () => {
  const {token}=useSelector(state=>state.auth);
  const [data,setData]=useState({});
  const [present,setPresent]=useState(0);
  const [loading,setLoading]=useState(true);

   useEffect(()=>{
     setLoading(true)
     fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/listuser`,{
       method:"GET",
       headers:{
         "Authorization":getLocalStorageData('taoakaean'),
       }
     }).then((e)=>e.json())
     .then((e)=>{
       setData(e[0])
       let c=0;
        e[0]?.attendance?.map(ele=>{
         if(ele.status==="present"){
           c++;
         }
         return ele
         }
         )
         setPresent(c);
         setLoading(false);
      })
     .catch((e)=>{console.error(e);setLoading(false)})
    },[token])
  return (
    <UserDashboard data={data}>
    <Routes>
            <Route path='/dashboard' element={<PrivateRoute><UserHome token={token} loading={loading} data={data} present={present} /></PrivateRoute>}/>
            <Route path='/profile' element={<PrivateRoute><Profile loading={loading} data={data} token={token}/></PrivateRoute>}/>
            <Route path='/liveexam' element={<PrivateRoute><Liveexam/></PrivateRoute>}/>
            <Route path='/lecture' element={<PrivateRoute><Lecture/></PrivateRoute>}/>
            <Route path='/download' element={<PrivateRoute><Download data={data}/></PrivateRoute>}/>
    </Routes>
    </UserDashboard>
  )
}

export default UserRoute