import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import {  BsPerson } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';
import { useState } from 'react';
import VerifySucess from './components/verifySucess';
import { useTitle } from '../../Utils/useTitle';

export default function VerifyCertificate() {
  useTitle("Noori Computer Classes || Certificate Verification Page")
  const [data,setData]=useState({name:"",dateOfBirth:"",regNumber:""});
  const [udata,setudata]=useState({})
  const [res,setres]=useState(false);
  const [verifypop,setverifypop]=useState(false)
 const handleSubmit=()=>{
    if(data.name=="" || data.dateOfBirth=="" || data.regNumber==""){
      return
    }
    setres(true)
    fetch(`${process.env.REACT_APP_BACKEND_URL}/certificate/verify`,{
      method:'POST',
      headers:{
        "Content-Type":"Application/json"
      },
      body:JSON.stringify(data)
    }).then((e)=>e.json())
    .then(e=>{
      setres(false);
      setverifypop(true);
      setudata(e);
      })
    .catch((e)=>{console.log(e);setres(false)})
   // console.log(data)


 }

  return (
    <Container bg="#9DC4FB" maxW="full" mt={0} centerContent overflow="hidden" paddingTop={20}>
      <Flex>
        {verifypop?<VerifySucess udata={udata}/>:null}
        <Box
          bg="#02054B"
          color="white"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 10, lg: 10 }}
          textAlign={'center'}
          
          >
          <Heading fontSize={{base:20,lg:28,md:24}}>Verify Your Certificate</Heading>
          <br/>
          <Box p={4}>
              <WrapItem>
                <Box bg="white" width="100%" borderRadius="lg">
                  <Box m={8} color="#0B0E3F">
                    <VStack spacing={5}>
                      <FormControl id="name">
                        <FormLabel>Your Name</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BsPerson color="gray.800" />}
                          />
                          <Input placeholder='Student Name' type="text" size="md" onChange={(e)=>setData({...data,name:e.target.value})} />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>Reg. Number</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<FaRegAddressCard color="gray.800" />}
                          />
                          <Input placeholder='02023-NC01-XXX' type="text" size="md" onChange={(e)=>setData({...data,regNumber:e.target.value})} />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>Date Of Birth</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <Input type="date" size="md" onChange={(e)=>setData({...data,dateOfBirth:e.target.value})}/>
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name" display={'flex'} justifyContent='center' >
                        <Button
                        width={'80%'}
                          variant="solid"
                          bg="#0D74FF"
                          color="white"
                          _hover={{bgColor:"green"}}
                          onClick={handleSubmit}
                          isLoading={res}>
                          Verify
                        </Button>
                      </FormControl>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
          </Box>
          <Text padding="5px" color={"white"}>Note : Certificate Alloted before 2023 have to be verified manually, <br/>Feel free to send mail at nooricomkne@gmail.com</Text>
        </Box>
      </Flex>
    </Container>
  );
}