import React from 'react'
import AlmuniQuotes from '../../Components/AlumniQuotes'
import Banner from '../../Components/Banner'
import Courses from '../../Components/Courses'

const HomePage = () => {

  return (
    <>
     <Banner/>
     <Courses/>
     <AlmuniQuotes/>
    </>
     
  )
}

export default HomePage