import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Center, SimpleGrid, background } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getQuizData } from '../../Redux/QuizQuestion/action'
import QuizCard from './QuizCard'
import LoadingSpinner from '../../Components/Loading'
import { useTitle } from '../../Utils/useTitle'

const QuizHome = () => {
  useTitle("Noori Computer Classes || Quiz Page")
  const dispatch = useDispatch()

  const { questionBank } = useSelector(store => store.question)

  useEffect(() => {
    dispatch(getQuizData())
  }, [])

  return (
    <div style={{ paddingTop: "100px" }}>
       
       <Center fontFamily={'cursive'} color={'rgb(224,41,135)'} fontSize={"20"} fontWeight={"800"}>Welcome To Quiz Page</Center>
  <Accordion defaultIndex={[0]} allowMultiple padding={10}>
  <AccordionItem >
    <h2>
      <AccordionButton _hover={()=>{}}  bgGradient={'linear(to-r, rgb(18,86,105), rgb(224,41,135))'} >
        <Box as="span" flex='1' textAlign='left' color={"white"}>
          Computer Science Quiz
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <SimpleGrid columns={{lg:3,md:2,base:1}} spacing={10} margin={"50px"}>
       {questionBank.length ==0 ?<LoadingSpinner/>:questionBank?.map((el,i) => {
            return el.visible && <QuizCard key={el._id+1} {...el} />
          })
        }
     </SimpleGrid>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton _hover={()=>{}} bgGradient={'linear(to-r, rgb(18,86,105), rgb(224,41,135))'} color='white'>
        <Box as="span" flex='1' textAlign='left'>
          Other Quiz
        </Box>
        <AccordionIcon/>
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <QuizCard/>
      </AccordionPanel>
  </AccordionItem>
</Accordion>
     
    </div>
  )
}

export default QuizHome