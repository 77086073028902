import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  useToast
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { deleteFromLocalStorage, getLocalStorageData, saveToLocalStorage } from '../../Utils/localStorage';
import Toast from 'noori-toast';

export default function ProceedQuiz({ _id, title, time, password }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState({ name: "", email: "", number: "", rollNumber: "", regNumber: "" });
  const [inputpassword, setInputpassword] = useState("");
  const navigate = useNavigate();
  const isErrore = input?.email === '';
  const isErrorn = input?.number === '';
  const isErrorname = input?.name === '';
  const handleProceed = () => {
    if (input?.email !== "" && input?.number != "" && input?.name != "") {
      if (password !== undefined && password !== null) {
        if (password !== inputpassword) {
          Toast("Incorrect Password", "error", "bottom-center");
          return;
        }
      }
      let check = getLocalStorageData(_id);
      if (!check) {
        deleteFromLocalStorage(_id)
      }
      const currentTime = new Date(); // Get current time
      const formattedTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      saveToLocalStorage(_id, { ...input, startedTime: formattedTime ,time:time,totalTime:time})
      navigate(`${title}/${_id}`)
    }
    else {
      toast({
        title: `Please enter all details`,
        status: 'error',
        isClosable: true,
        position: "top"
      })
    }
  }

  return (
    <>
      <Button mt={10}
        w={'full'}
        bg={'rgb(237,100,166)'}
        color={'white'}
        rounded={'xl'}
        boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
        _hover={{
          bg: 'pink.300',
        }}
        _focus={{
          bg: 'pink.300',
        }}
        onClick={onOpen}>Attempt</Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent marginTop={100}>
          <ModalHeader>Enter your details to proceed</ModalHeader>
          <ModalCloseButton />
          <FormControl isInvalid={isErrore}>
            <ModalBody pb={6}>

              <FormLabel>Name</FormLabel>
              <Input type='text' value={input?.name} onChange={(e) => setInput({ ...input, name: e.target.value })} />
              {!isErrorname ? (
                <FormHelperText> Enter your correct name</FormHelperText>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
              <FormLabel>Email</FormLabel>
              <Input type='email' value={input?.email} onChange={(e) => setInput({ ...input, email: e.target.value })} />
              {!isErrore ? (
                <FormHelperText> Enter your correct Email Id</FormHelperText>
              ) : (
                <FormErrorMessage>Email is required.</FormErrorMessage>
              )}
              <FormLabel>Mobile Number</FormLabel>
              <Input type='number' value={input?.number} onChange={(e) => setInput({ ...input, number: e.target.value })} />
              {!isErrorn ? (
                <FormHelperText> Enter your correct Mobile Number</FormHelperText>
              ) : (
                <FormErrorMessage>Mobile Number is required.</FormErrorMessage>
              )}
              <FormLabel>Roll Number (optional)</FormLabel>
              <Input type='string' value={input?.rollNumber} onChange={(e) => setInput({ ...input, rollNumber: e.target.value })} />

              <FormLabel>Reg Number (optional)</FormLabel>
              <Input type='string' value={input?.regNumber} onChange={(e) => setInput({ ...input, regNumber: e.target.value })} />

              {password !== null && password !== undefined ? <> <FormLabel> Password </FormLabel>
                <Input type='string' value={inputpassword} onChange={(e) => setInputpassword(e.target.value)} />
              </> : null}

            </ModalBody>

            <ModalFooter>
              <Button
                mt={10}
                w={'half'}
                bg={'green.400'}
                color={'white'}
                rounded={'xl'}
                boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
                _hover={{
                  bg: 'green.500',
                }}
                _focus={{
                  bg: 'green.500',
                }}
                onClick={handleProceed}
              >Proceed
              </Button>
              <Button
                mt={10}
                ml={5}
                w={'half'}
                bg={'orange.400'}
                color={'white'}
                rounded={'xl'}
                boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
                _hover={{
                  bg: 'orange.500',
                }}
                _focus={{
                  bg: 'orange.500',
                }}
                onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </FormControl>
        </ModalContent>
      </Modal>
    </>
  )
}