import { Accordion, AccordionButton, AccordionPanel, AccordionItem, Box, AccordionIcon, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

function ListUser() {
    let toast = useToast();
    const [data, setData] = useState([]);
    useEffect(() => {

        fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/listuser`)
            .then(e => e.json())
            .then(e => setData(e))
            .catch(e => toast({
                title: "Info",
                description: "Something went wrong! Refresh/Contact Admin",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top"
            }))
    }, [])

    return (
        <Box width={"100%"}>
            <h2 style={{color:'teal'}}>Reg Number : -</h2><br/>
            <Accordion defaultIndex={[0]} allowMultiple>
                {
                    data && data.map((ele, index) => {

                        return <AccordionItem key={ele._id}>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left'>
                                        <p>{ele.regNumber}</p>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <b>Name:-</b> {ele.name}<br />
                                <b>Father's Name:-</b> {ele.name}<br />
                                <b>Mother's Name:-</b> {ele.name}<br />
                                <b>Dob:- </b>{ele.dateOfBirth}<br />
                                <b>Date Of Admission :-</b> {ele.dateOfAddmission}<br />
                                <b>Course :-</b> {ele.course}<br />
                                <b>email :- </b>{ele.email}<br />
                                <b>Registration Number :- </b>{ele.regNumber}<br />
                                <b>Roll No :- </b>{ele.rollNo}<br />
                                <b>Address :-</b> {ele.address}<br />
                            </AccordionPanel>
                        </AccordionItem>
                    })
                }

            </Accordion>
        </Box>
    )
}

export default ListUser