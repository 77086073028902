import { 
         Modal,
         ModalBody,
         ModalCloseButton,
         ModalContent,
         ModalHeader,
         ModalOverlay,
         Text,
         useDisclosure
         } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import verifyimg from './verify.png';
import notfound from '../../../Assests/notfound.gif';


export default function VerifySucess({udata}) {
  
    const OverlayOne = () => (
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    )
  
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = useState(<OverlayOne />)
  
    useEffect(()=>{
        onOpen();
    },[udata])
    return (
      <>
      <div>
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          {overlay}
          <ModalContent  alignItems='center'>
            <ModalHeader>{udata.error?"User not found":"User Found"}</ModalHeader>
            <ModalCloseButton/>
            <ModalBody display='flex' flexDir='column' alignItems='center' justifyContent='center' p={10}>
              {udata.error?<img src={notfound} alt="Not verified" width='50%' />:<img src={verifyimg} alt="verified" width='50%' />}
              <br/>{udata.error?<Text fontWeight='bold' borderRadius='4px' bgColor='black' color='white' p={5}>Invalid Details! Please re-check </Text>:
               <div className="text-details">
               <p><strong>Name:</strong> {udata?.name}</p>
               <p><strong>Father's Name:</strong> {udata?.fatherName}</p>
               <p><strong>Marks:</strong> {udata?.marks}</p>
               <p><strong>Grade:</strong> {udata?.grade}</p>
               <p><strong>Date of Passing:</strong> {udata?.dateOfCompletion}</p>
             </div>}
            </ModalBody>
          </ModalContent>
        </Modal>
        </div>
      </>
    )
  }